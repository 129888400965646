import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter } from 'react-router-dom';
import store from './store';
// import { injectStore } from "./axios-config";
import "./i18n";
import { ForceVersionUpdateListener } from './contexts/ForceVersionUpdateListener';
import { SoundProvider } from "contexts/SoundContext";

//TODO: Handle initial language on the app
const getBrowserLanguage = () => {
  const language = navigator.languages && navigator.languages[0] || // Chrome / Firefox
    navigator.language ||   // All browsers
    navigator.userLanguage; // IE <= 10
    console.log(language);
  return language;
};
const language = getBrowserLanguage();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
          <SoundProvider>
            <App />
          <ForceVersionUpdateListener />
          </SoundProvider>

      </BrowserRouter>
    </Provider>
  </React.StrictMode >,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
